<template>
  <div class="container">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="getList"
    >

      <div class="diy-cell" v-for="item in list" :key="item.id" @click="goDetail(item.roomId)">
        <div class="top fx_ai">
          <div class="title van-ellipsis fx1">{{ item.estateName }}</div>

          <van-tag v-if="item.checkStatus == '0'" size="medium" color="#fbe7e6" text-color="#E35D58">{{ item.checkStatusName }}</van-tag>
          <van-tag v-else-if="item.checkStatus == '1'" size="medium" color="#daf5dc" text-color="#0DB918">{{ item.checkStatusName }}</van-tag>
          <van-tag v-else-if="item.checkStatus == '-1'" size="medium" color="#f4f4f5" text-color="#909399">{{ item.checkStatusName }}</van-tag>
        </div>
        <p>房间号：{{ item.fullRoomName }}</p>
        <p>业主姓名：{{ item.name }}</p>
        <p>房屋状态：{{ item.roomStatusName }}</p>
        <p>身份：{{ item.categoryName }}</p>
        
      </div>
    </van-list>
  </div>
</template>

<script>  

import { myProperty } from '@/api/houseApi.js'
export default {
  name: 'memberVerifyList',
  components: {
  }, 
  data() {
    return {
      // tagList: [{
      //   key: -1,
      //   color: '#f4f4f5',
      //   textColor: '#909399',
      // },{
      //   key: 0,
      //   color: '#fbe7e6',
      //   textColor: '#E35D58',
      // },{
      //   key: 1,
      //   color: '#daf5dc',
      //   textColor: '#0DB918',
      // }],
      value1: 0,
      value2: 'a',
      option1: [
        { text: '全部状态', value: 0 },
        { text: '新款商品', value: 1 },
        { text: '活动商品', value: 2 },
      ],
      option2: [
        { text: '全部户室', value: 'a' },
        { text: '好评排序', value: 'b' },
        { text: '销量排序', value: 'c' },
      ],
      list: [],
      loading: false,
      finished: false,
    }
  },
  // 计算属性
  computed: { 
  },

  created() {
    // this.getList()
  },  
  methods: {
    getList() {
      myProperty().then(res => {
        if(res.code == 200) {
          this.loading = false
          this.finished = true; 
          this.list = res.data
        }
      })
      
    },
    goDetail(id) {
      this.$router.push({
        path: '/myPropertyDetail?id=' + id,
      })
    }
  }
}
</script>


<style lang='scss' scoped>
.container {
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
}
.diy-cell{
  margin: 0 15px 12px ;
  border-radius: 12px;
  padding: .53rem;
  // padding-bottom: .21rem;
  background: #fff;
  font-size: .37rem;
  .top{
    margin-bottom: 8px;
    .title{
      font-weight: bold;
    }
  }
  .time{
    font-size: .32rem;
    color: #646466;
    margin-bottom: 8px;
  }
  .price {
    color: #fb001e;
  }
  .van-button {
    border-radius: 4px;
  }
}
</style>
